import React, { useState } from 'react'
import NetlifyForm from 'react-ssg-netlify-forms'
import '../styles/components/_contact-form.scss'


export default function ContactForm({classes}) {

    // Change to neltify form https://github.com/jon-fm/react-ssg-netlify-forms

    const [emailValue, setEmailValue] = useState('')
    const [messageValue, setMessageValue] = useState('')
    
    const [successMessage, setSuccessMessage] = useState(false);

    function postSubmit() {
        setSuccessMessage(true);
        setEmailValue('')
        setMessageValue('')

        setTimeout(() => {
            setSuccessMessage(false);
        }, 2000);
    }


    function handleEmailChange(e) {
        setEmailValue(e.target.value)
    } 
    
    function handleMessageChange(e) {
        setMessageValue(e.target.textContent)
    } 

    
    return (

        <div className={`contact-form width-100 ${classes}`}>
            <NetlifyForm className='contact-form flex-container' aria-label='Contact' formName='Contact' formValues={{email: emailValue, message: messageValue}} postSubmit={postSubmit}>

                <input type='email' name='email' placeholder='Your E-Mail' value={emailValue} onChange={handleEmailChange} aria-label='Your E-Mail' required></input>
                <textarea name='message' style={{display: 'none'}} value={messageValue} onChange={handleMessageChange} aria-label='Your Message' ></textarea> 
                {/* THE ABOVE LINE IS NEEDED TO MAKE THE FORM WORK, THE FORM NEEDS AN ACTUAL FORM FIELD (NOT A CONTENTEDITABLE SPAN) WITH A MATCHING 'NAME' ATTRIBUTE FOR THIS TO WORK */}
                <span className="textarea" role="textbox" dangerouslySetInnerHTML={{__html: messageValue}} onBlur={handleMessageChange} aria-label='Your Message' contentEditable></span>
                <button type='submit' className={`${successMessage === true ? 'active' : 'inactive'} button`}>Send</button> 

            </NetlifyForm>
        </div>

    )
}
