/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import NavBar from "./NavBar";



import "../styles/_layout.scss";
import Footer from "./Footer";
import ParticlesComponent from "./ParticlesComponent";
import SEO from "./seo";

const Layout = ({ children, width_restrict_all, location, meta_title, meta_desc, meta_img }) => {

  return (
    <>
    <SEO title={meta_title} description={meta_desc} image={meta_img}></SEO>
      <div id='page-container' className='flex-container-column'>
        <ParticlesComponent />
        <div id='content-wrap'>
            <NavBar />
            <main className={`${width_restrict_all ? "width-restricted-container" : ''}${location ? location : '' }`}>
              {children}
            </main>
        </div>
        <Footer />
    </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
