import React, { useEffect, useState } from 'react'
import Particles from "react-particles-js";
import particlesConfig from "../assets/particlesjs-config.json";
import "../styles/components/_particles.scss";

export default function ParticlesComponent() {

    let [loadType, setLoadType] = useState('fade-in');
    let [loadParticles, setLoadParticles] = useState(false)

    useEffect(() => {
        

        // This if statement block checks if we're on the home page and if it's a new refresh.  If it is, then we delay the particles being rendered to try and make the main home page animation performance better.
        if(window.location.pathname==="/" && !window.sessionStorage.getItem('site_visit')) {
            setTimeout(() => {setLoadParticles(true)}, 1500);
        } else {
            setLoadParticles(true)
        }
        
        if(!window.sessionStorage.getItem('site_visit')) {
            window.sessionStorage.setItem('site_visit', 1);
        } else {
            setLoadType('load-in')
        }

        window.addEventListener("beforeunload", () => {
            sessionStorage.removeItem("site_visit")
        })



    }, [])

    let config = particlesConfig;

    if (typeof window === `undefined`) {

        if(!loadParticles) return null;

        return (
            <div className={`bg-particles-wrapper ${loadType}`}>
                <Particles params={config} />
            </div>
        )
    }

    let window_width = window.outerWidth

    function updateConfig(value) {
        let c = Object.assign({}, particlesConfig);
        c.particles.number.value = value;
        return c;
    }

    if(window_width && window_width < 600) {
        // Mobile
        config = updateConfig(30)
        
    } else if (window_width && window_width  < 992) {
        // Tablet
        config = updateConfig(70)

    }

    if(!loadParticles) return null;

    return (
        <div className={`bg-particles-wrapper ${loadType}`}>
            {loadParticles ? <Particles params={config} /> : null}
        </div>
    )
}
