import React, { useState } from 'react'
import { Link } from "gatsby"
import "../styles/components/_navbar.scss";
import logo_svg from '../assets/jmuk_logo.svg';

export default function NavBar({data}) {

    return (
        <>
            <Nav logo={logo_svg} />
            <MobilePopout />
        </>
    )
}

function Nav({logo, children}) {

    return (
        <nav className="navbar">
            <div className="width-restricted-container navbar__inner">
                <div className="navbar__inner__left-nav">
                    <NavLink to="/" className="navbar__inner__left-nav__logo-wrapper" >
                        <img src={logo} className="navbar__inner__left-nav__logo" alt='JonnyMarsUK Logo' />
                    </NavLink>
                </div>
                <ul className="navbar__inner__right-nav">
                    <li><NavLink to="/about">About</NavLink></li>
                    <li><NavLink to="/projects">Projects</NavLink></li>
                    <li><NavLink to="/blog">Blog</NavLink></li>
                    <li><NavLink to="/contact">Contact</NavLink></li>
                </ul>
            </div>
            {children}
        </nav>
    )

}

function MobilePopout() {
    
    let [mobile_toggle, setMobileToggle] = useState("closed");

    function mobileToggle() {
        setMobileToggle(mobile_toggle === "open" ? "closed" : "open")
    }

    function activeLinkClicked(e) {
        // If user clicks link for page currently on, we close the nav
        if(e.target.className.indexOf("current") > -1) mobileToggle();
    }

    let toggle_class = mobile_toggle === "closed" ? "" : "active";

    return (
        <div className="mobile-nav">

            <nav className={`mobile-nav__popout ${toggle_class}`}>
                    <NavLink to="/" clickEvent={activeLinkClicked}>Home</NavLink>
                    <NavLink to="/about" clickEvent={activeLinkClicked}>About</NavLink>
                    <NavLink to="/projects" clickEvent={activeLinkClicked}>Projects</NavLink>
                    <NavLink to="/blog" clickEvent={activeLinkClicked}>Blog</NavLink>
                    <NavLink to="/contact" clickEvent={activeLinkClicked}>Contact</NavLink>
            </nav>
            <div className="mobile-nav__toggle-container">
                <div className={`mobile-nav__background ${toggle_class}`}></div>
                <button className={`mobile-nav__toggle ${toggle_class}`} onClick={mobileToggle} type='button' aria-label={`${mobile_toggle === "open" ? "Close" : "Open"} navigation menu`}>
                    <div className={`mobile-nav__toggle__icon ${toggle_class}`}>
                        <div className="mobile-nav__toggle__icon__hamburger">
                            <div className="mobile-nav__toggle__icon__hamburger__box">
                                <div className="mobile-nav__toggle__icon__hamburger__box__inner">
                                </div>
                            </div>
                        </div>
                    </div>
                </button>
            </div>

        </div>
    )


}

function NavLink({children, to, clickEvent}) {

    return <Link to={to} activeClassName="current" onClick={clickEvent ? clickEvent : null}>
        {children}
    </Link>

}