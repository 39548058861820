import React from 'react'
import '../styles/components/_footer.scss';

import instaIcon from '../assets/icon/insta_icon.png';
import twitterIcon from '../assets/icon/twitter_icon.png';
import linkedinIcon from '../assets/icon/linkedin_icon.png';
import ContactForm from './ContactForm';


export default function Footer() {


    return (
        <footer id="footer" className='width-100'>

            <div className='width-restricted-container'>

                <div className='footer-actionable flex-container'>
                    <div className='footer-contact width-50 m-width-100 t-width-100'>
                        <h3>Contact Me</h3>
                        <ContactForm classes='footer-contact-form' />
                    </div>
                    <div className='footer-socials width-50 m-width-100 t-width-100'>
                        <h3>Socials</h3>
                        <SocialsLink link_text='@JonnyMarsUK' url='https://twitter.com/JonnyMarsUK' icon={twitterIcon} />
                        <SocialsLink link_text='@jonnymars.uk' url='https://www.instagram.com/jonnymars.uk/' icon={instaIcon} />
                        <SocialsLink link_text='jonnymarsuk' url='https://www.linkedin.com/in/jonnymarsuk/' icon={linkedinIcon} />
                    </div>
                </div>
                <div className='footer-info width-50'>
                    
                </div>

            </div>

        </footer>
    )
}


function SocialsLink({link_text, url, icon}) {

    

    return (

        <div className='socials-link-outer flex-container'>
            <a href={url} className='socials-link flex-container flex-nowrap'>
                <div className='socials-link-img'>
                        <img src={icon} alt=''></img>
                </div>
                <div className='socials-link-link'>
                    {link_text}
                </div>
            </a>
        </div>
    )

}